import React from 'react';
import { Text, View, TouchableOpacity, Alert, StyleSheet, Image, Platform, Linking } from "react-native";
import Clipboard from '@react-native-community/clipboard';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { styles } from "../styles";
import { connect } from 'react-redux';
import createGameJoinQRAction from '../actions/createGameJoinQRAction';
import { APP_HOST_DOMAIN } from "../src/constants";
import { useHistory } from "./navigation/react-router";

class GameShare extends React.Component {
    render = () => {
        return (
            <View>
                <Text style={[styles.label, { fontSize: 20 }]}>
                    Want to join <Text style={styles.italic}>{this.props.gameEventReducer.admin.name}'s</Text> Game <Text style={styles.italic}>{this.props.gameEventReducer.game.name}</Text>?
                    To join this game, try opening your phone's camera app, point it at the QR code below and tap the link.
                </Text>
                {/* <Text
                    style={styles.label}
                    onPress={() => Linking.openURL(`${APP_HOST_DOMAIN}/join/${this.props.game.uuid}`)}
                >
                    {`${APP_HOST_DOMAIN}/join/${this.props.game.uuid}`}
                </Text> */}
                {/* <ResultsLink /> */}
                {(this.props.qrReducer.generated == true) 
                    ? <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',                        
                    }}>
                        <Image
                            style={{
                                width: 300,
                                height: 300,
                                borderRadius: 20
                            }}
                            source={{
                                uri: this.props.qrReducer.image
                            }}
                        />
                        {Platform.OS === "web"
                            ? <View style={styles.inputContainer}>
                                <TouchableOpacity
                                    style={styles.button}
                                >
                                    <CopyToClipboard text={this.props.qrReducer.string}>
                                        <Text style={styles.buttonText}>
                                            Copy invite
                                        </Text>
                                    </CopyToClipboard>
                                </TouchableOpacity>
                            </View>
                            : <View style={styles.inputContainer}>
                                <TouchableOpacity
                                        style={styles.button}
                                        onPress={() => Clipboard.setString(this.props.qrReducer.string)}
                                    >
                                        <Text style={styles.buttonText}>
                                            Copy invite to clipboard
                                        </Text>
                                </TouchableOpacity>
                            </View>
                        }
                    </View>
                    : <Text style={styles.label}>
                        loading...
                    </Text>}
            </View>            
        )        
    }
}

const ResultsLink = () => {
    const history = useHistory();
    return (
        <Text
        style={styles.label}
        onPress={() => history.push(`/results`)}
        >
            {`${APP_HOST_DOMAIN}/results`}
        </Text>        
    )
}

const mapStateToProps=(state)=>{
    return {
        gameEventReducer: state.gameEventReducer,
        qrReducer: state.qrReducer,
    }
};

function mapDispatchToProps(dispatch) {
    return {
        createGameJoinQRAction: (string) => dispatch(createGameJoinQRAction(string))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameShare);