
import React, { useRef, useState } from "react";
import { TouchableOpacity, Text, View, Animated, FlatList } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import { colors, styles } from "../../styles";
import { hexToRGB } from "../../src/helpers";
import Hamburger from 'react-native-animated-hamburger';
import { useHistory } from "../../components/navigation/react-router";
import { connect } from 'react-redux';
import { Button } from "react-native-elements";
import leaveGameAction from "../../actions/leaveGameAction";

class NavDrawer extends React.Component {
    render() {
      return (
        <SlideMenu {...this.props} state={this.state} />
      )
    }
}

function SlideMenu (props) {
    const [ state, setState ] = useState({
        navDrawerOpen: null,
        navTo: null
    });

    const [leaveConfirmVisible, setLeaveConfirmVisible] = useState(false);

    const toggleLeaveConfirm = () => {
      setLeaveConfirmVisible(!leaveConfirmVisible);
    };    
    if (leaveConfirmVisible === true && props.gameEventReducer.game == null) {
        toggleLeaveConfirm();
    }

    const history = useHistory();

    const animOpen = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0
    const animateOpen = () => {
        Animated.timing(
            animOpen,
            {
                fromValue: 0,
                toValue: 1,
                duration: 500,
            }
        ).start(() => {
            animOpen.setValue(1);
        });
    }


    if ( state.navDrawerOpen !== null) {
        animateOpen();
    }

    const toggleDrawer = () => {
        animOpen.setValue(0);
        setState({
            ...state,
            navDrawerOpen: !state.navDrawerOpen
        });
    }

    // close drawer when navigation completes
    history.listen((location, action) => {
        state.navDrawerOpen === true  ? toggleDrawer() : null;
    })

    return (
        <View style={{
            top: 0,
            right: 0,
            height: "100%",
            width: "100%",
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0)",
            overflow: "hidden"
        }}
            pointerEvents="box-none"
        >
            <Animated.View
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    // left: state.navDrawerOpen ? 0 : "100%",
                    top: 0,
                    flex: 1,
                    backgroundColor: "rgba(0,0,0,0)",
                    left: state.navDrawerOpen === null ? "100%" : animOpen.interpolate({
                            inputRange: [0, 1],
                            outputRange: state.navDrawerOpen ? ["100%", "0%"] : ["0%", "100%"],
                            // outputRange: ["100%", "0%"],
                            extrapolateRight: 'clamp'
                        })
                }}
            >
                <LinearGradient style={{
                        padding: 20,
                        flex: 1,
                        width: "100%",
                        backgroundColor: "rgba(255,255,255,0.5)",
                        justifyContent: 'center',
                    }}
                    colors={[`rgba(${hexToRGB(colors.pink)}, 0.9)`, `rgba(${hexToRGB("#ec0067")},0.9)`]}
                >
                    {!props.gameEventReducer?.player 
                        ? null 
                        : <View>
                            <Text style={[styles.label, styles.navigationMenuText]}>
                                Hi <Text style={{fontStyle: "italic"}}>{props.gameEventReducer?.player?.name}</Text>, you're currently playing 
                                <Text style={{fontStyle: "italic"}}>{props.gameEventReducer?.game?.name}</Text>, 
                                and <Text style={{fontStyle: "italic"}}>{props.gameEventReducer?.admin?.name}</Text> is the host.
                            </Text>
                            <View style={{
                                borderBottomColor: '#ffffff',
                                borderBottomWidth: 3,
                                marginBottom: 20
                            }}
                            />                            
                        </View>
                    }
                
                    <FlatList
                        data={props.navItems}
                        keyExtractor={item => item.key}
                        renderItem={({ item }) => (
                            <TouchableOpacity style={{
                                    width: "100%",
                                }}
                                onPress={() => {
                                    if (item.path == '/reset') {
                                        toggleLeaveConfirm();
                                    } else {
                                        history.push(item.path);                        
                                        // toggleDrawer();
                                    }
                                }}
                            >
                                <Text style={[styles.label, styles.navigationMenuitem]}>
                                    {item.title}
                                </Text>
                            </TouchableOpacity>
                        )}
                    />                 
                </LinearGradient>                

            </Animated.View>
            
            <TouchableOpacity style={{
                position: "absolute",
                top: 0,
                right: 0,
                background: "rgba(0,0,0,0)",
                width: 35,
                height: 35,
                marginTop: 15,
                marginRight: 15,
                zIndex: 1,
                justifyContent: 'center',
                flex: 1,
                alignItems: "cemter"
            }}
            >
                <Hamburger type="cross" active={state.navDrawerOpen===true} onPress={() => toggleDrawer()}
                    containerStyle={{
                        width: "100%",
                        height: "100%",
                        flex: 1,
                    }}
                    color="#ffffff"
                    underlayColor="transparent"
                >
                </Hamburger>
            </TouchableOpacity>
            { !leaveConfirmVisible 
                ? null 
                : <View style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    flex: 1,
                    height: "100%",
                    width: "100%",
                    padding: 20,
                    backgroundColor: "rgba(0,0,0,0.9)",
                    justifyContent: 'center',
                    alignItems: "center"
                }}>
                    <Text style={{fontSize: 40, color: "#ffffff", textAlign: "center", paddingBottom: 30}}>
                        You sure you want to leave? You can't rejoin once you've left.
                    </Text>
                    <View style={{
                        flexDirection: 'row'
                    }}>
                        <Button buttonStyle={{
                            backgroundColor: "rgb(204,25,25)",
                            marginBottom: 15,
                        }} 
                            title="Leave"
                            containerStyle={{
                                width: "50%",
                                paddingRight: 15
                            }}
                            onPress={()=>{ props.leaveGameAction(); }}
                        >
                            
                        </Button>
                        <Button buttonStyle={{
                            backgroundColor: "rgb(21,177,47)",
                            marginBottom: 15,
                        }} 
                            title="Return"
                            containerStyle={{
                                width: "50%",
                                paddingLeft: 15
                            }}
                            onPress={()=>{ toggleDrawer(); setLeaveConfirmVisible(0) }}
                        />                        
                    </View>
                </View>
            }            
        </View>


    )
}



const mapStateToProps=(state)=>{
    return {
        gameEventReducer: state.gameEventReducer,
    }
};

function mapDispatchToProps(dispatch) {
    return {
        leaveGameAction: () => dispatch(leaveGameAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavDrawer);