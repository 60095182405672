import {QR_GENERATED, QR_GENERATING, QR_ERROR} from './actionTypes';
import qrcode from "qrcode";

export default function createGameJoinQRAction(url) {
    return (dispatch) => {
        dispatch({
            type : QR_GENERATING,
            payload: {
                string: url
            }
        });

        qrcode.toDataURL(url, (err, string) => {
            return err 
                ? dispatch({
                        type : QR_ERROR,
                        payload: {
                            error: err
                        }
                })
                : dispatch({
                    type : QR_GENERATED,
                    payload: {
                        image: string
                    }
                })
        });
    };        
};

