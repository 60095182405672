import React, { useState } from 'react';
import { Button, View, Text, Platform, UIManager, TouchableOpacity } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import GameCreateScreen from './screens/GameCreateScreen';
import PlayerJoinScreen from './screens/PlayerJoinScreen';
import PlayerLobbyScreen from './screens/PlayerLobbyScreen';
import GameSummaryScreen from './screens/GameSummaryScreen';
import GameLeftScreen from './screens/GameLeftScreen';
import NavDrawer from './components/navigation/NavDrawer';
import Animation from './components/Animation';

import PlayersManageScreen from './screens/GameManageScreen';
import gameEventReducer from './reducers/gameEventReducer';
import qrReducer from './reducers/qrReducer';
import playersReducer from './reducers/playersReducer';
import websocketSubscriptionReducer from './reducers/websocketSubscriptionReducer';
import joinGameReducer from './reducers/joinGameReducer';
import chatReducer from './reducers/chatReducer';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { WS_DOMAIN } from './src/constants';
import { PONG_SEND } from './actions/websocketActionTypes';
import reduxWebsocket, { connect } from '@giantmachines/redux-websocket';
import pongAction from "./actions/websocket/pongAction";
import subscribeToUpdatesAction from "./actions/websocket/subscribeToUpdatesAction";
import { Router, Route, Link } from "./components/navigation/react-router";
import { styles } from "./styles";

const composeEnhancer = (!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  actionsBlacklist: [
    "REDUX_WEBSOCKET::SEND",
  ]  
}) || compose;
let pongInteval;
let store = createStore(
  combineReducers({
    gameEventReducer: gameEventReducer,
    qrReducer: qrReducer,
    chatReducer: chatReducer,
    playersReducer: playersReducer,
    websocketSubscriptionReducer: websocketSubscriptionReducer,
    joinGameReducer: joinGameReducer
  }),
  composeEnhancer(applyMiddleware(thunk, reduxWebsocket({
  // Defaults to 'REDUX_WEBSOCKET'. Use this option to set a custom action type
  // prefix. This is useful when you're creating multiple instances of the
  // middleware, and need to handle actions dispatched by each middleware instance separately.    
    // prefix?: string;
    // Defaults to 2000. Amount of time to wait between reconnection attempts.
    reconnectInterval:2000,
    // Defaults to false. If set to true, will attempt to reconnect when conn is closed without error event
    // e.g. when server closes connection
    reconnectOnClose: true,
    // Callback when the WebSocket connection is open. Useful for when you
    // need a reference to the WebSocket instance.
    onOpen: (socket) => {
      store.dispatch(subscribeToUpdatesAction());
      if (pongInteval) {
        clearInterval(pongInteval);
      }
      pongInteval = setInterval(() => {
        store.dispatch(pongAction());
      }, 2000);      
    },
    // Custom function to serialize your payload before sending. Defaults to JSON.stringify
    // but you could use this function to send any format you like, including binary
    // serializer?: (payload: any) => string | ArrayBuffer | ArrayBufferView | Blob;
    // Custom function to deserialize the message data sent from the server. By default the
    // message data gets passed through as is.
    deserializer: (message) => JSON.parse(message),
    // Custom function to serialize the timestamp. The default behavior maintains the timestamp
    // as a Date but you could use this function to store it as a string or number.
    // dateSerializer?: (date: Date) => string | number;    
  })))
);

store.dispatch(connect(WS_DOMAIN));

// react animations
if (
  Platform.OS === "android" &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

function HomeScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Button
        onPress={navigation.openDrawer}
        title="Open navigation drawer"
      />
      <Button
        onPress={() => navigation.navigate('Notifications')}
        title="Go to notifications"
      />
    </View>
  );
}

function NotificationsScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Button
        onPress={navigation.openDrawer}
        title="Open navigation drawer"
      />
      <Button
        onPress={() => navigation.goBack()}
        title="Go back home"
      />
    </View>
  );
}

const Drawer = createDrawerNavigator();

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          {/* <View style={styles.nav}> 
            <Link to="/">
              <Text>Home</Text>
            </Link>
            <Link to="/about">
              <Text>Join</Text>
            </Link>
          </View> */}
          <Route exact path="/" component={GameCreateScreen} />
          <Route path="/join/:gameUuid" component={PlayerJoinScreen} />      
          <Route path="/lobby" component={PlayerLobbyScreen} />      
          <Route path="/results" component={GameSummaryScreen} />
          <Route path="/about" component={Animation} />
          <Route path="/so-long" component={GameLeftScreen} />
          <Route path="/test" component={Animation} />
          
          <NavDrawer navItems={[{
            key: 0,
            title: "Game",
            path: "/",
          }, {
            key: 1,
            title: "About",
            path: "/about"
          }, {
            key: 2,
            title: "Leave Game",
            path: "/reset"
          }]} />

        </Router>
      </Provider>
    );
  }
}