import { SUBSCRIBE_TO_UPDATES_ADMIN_REQUEST, SUBSCRIBE_TO_UPDATES_PLAYER_REQUEST } from "../websocketActionTypes";
import { send } from '@giantmachines/redux-websocket';

export default function subscribeToUpdatesAction() {
    return (dispatch, getState) => {
        const state = getState();

        if (state.gameEventReducer.game === null) {
            return;
        }

        if (state.gameEventReducer.isAdmin == true) {
            dispatch(send({ 
                action: SUBSCRIBE_TO_UPDATES_ADMIN_REQUEST,
                gameUuid: state.gameEventReducer.game.uuid,
                adminUuid: state.gameEventReducer.admin.uuid
             }));
        } else {
            if (state.joinGameReducer.player !== null) {
                dispatch(send({ 
                    action: SUBSCRIBE_TO_UPDATES_PLAYER_REQUEST,
                    gameUuid: state.gameEventReducer.game.uuid,
                    playerUuid: state.joinGameReducer.player.uuid
                 }));            
            }
        }
    };
};

