export const SUBSCRIBE = "SUBSCRIBE";
export const PONG_SEND = "PONG_SEND";

export const NEW_PLAYER = "NEW_PLAYER";
export const PLAYER_UPDATE = "PLAYER_UPDATE";

export const PLAYER_SCORE_REQUEST = "PLAYER_SCORE_REQUEST";
export const PLAYER_SCORE_RESPONSE = "PLAYER_SCORE_RESPONSE";

export const PLAYER_BUZZ_REQUEST = "PLAYER_BUZZ_REQUEST";
export const PLAYER_BUZZ_RESPONSE = "PLAYER_BUZZ_RESPONSE";

export const GAME_REFRESH = "GAME_REFRESH";
export const GAME_REFRESH_PLAYERS = "GAME_REFRESH_PLAYERS";

export const GAME_BUZZER_STATUS_REQUEST = "GAME_BUZZER_STATUS_REQUEST";
export const GAME_BUZZER_STATUS_RESPONSE = "GAME_BUZZER_STATUS_RESPONSE";

export const GAME_BUZZER_CLEAR_ALL_REQUEST = "GAME_BUZZER_CLEAR_ALL_REQUEST";
export const GAME_BUZZER_CLEAR_ALL_RESPONSE = "GAME_BUZZER_CLEAR_ALL_RESPONSE";

export const GAME_START_REQUEST = "GAME_START_REQUEST";
export const GAME_START_RESPONSE = "GAME_START_RESPONSE";
export const GAME_END_REQUEST = "GAME_END_REQUEST";
export const GAME_END_RESPONSE = "GAME_END_RESPONSE";

export const SUBSCRIBE_TO_UPDATES_ADMIN_REQUEST = "SUBSCRIBE_TO_UPDATES_ADMIN_REQUEST";
export const SUBSCRIBE_TO_UPDATES_ADMIN_STATUS = "SUBSCRIBE_TO_UPDATES_ADMIN_STATUS";
export const SUBSCRIBE_TO_UPDATES_PLAYER_REQUEST = "SUBSCRIBE_TO_UPDATES_PLAYER_REQUEST";
export const SUBSCRIBE_TO_UPDATES_PLAYER_STATUS = "SUBSCRIBE_TO_UPDATES_PLAYER_STATUS";

export const REDUX_WEBSOCKET_MESSAGE = "REDUX_WEBSOCKET::MESSAGE";
export const REDUX_WEBSOCKET_CLOSED = "REDUX_WEBSOCKET::CLOSED";

export const WS_GAME_REQUEST_RESPONSE = "WS_GAME_REQUEST_RESPONSE";