export const NEW_GAME_REQUEST_START = "NEW_GAME_REQUEST_START";
export const NEW_GAME_REQUEST_RESPONSE = "NEW_GAME_REQUEST_RESPONSE";
export const NEW_GAME_REQUEST_ERROR = "NEW_GAME_REQUEST_ERROR";

export const QR_GENERATING = "QR_GENERATING";
export const QR_GENERATED = "QR_GENERATED";
export const QR_ERROR = "QR_ERROR";

export const GAME_REQUEST_START = "GAME_REQUEST_START";
export const GAME_REQUEST_RESPONSE = "GAME_REQUEST_RESPONSE";
export const GAME_REQUEST_ERROR = "GAME_REQUEST_ERROR";

export const JOIN_GAME_REQUEST_START = "JOIN_GAME_REQUEST_START";
export const JOIN_GAME_REQUEST_RESPONSE = "JOIN_GAME_REQUEST_RESPONSE";
export const JOIN_GAME_REQUEST_ERROR = "JOIN_GAME_REQUEST_ERROR";

export const RESTORE_ADMIN_START = "RESTORE_ADMIN_START";
export const RESTORE_ADMIN_RESPONSE = "RESTORE_ADMIN_RESPONSE";
export const RESTORE_ADMIN_ERROR = "RESTORE_ADMIN_ERROR";

export const RESTORE_PLAYER_START = "RESTORE_PLAYER_START";
export const RESTORE_PLAYER_RESPONSE = "RESTORE_PLAYER_RESPONSE";
export const RESTORE_PLAYER_ERROR = "RESTORE_PLAYER_ERROR";

export const LEAVE_GAME = "LEAVE_GAME";