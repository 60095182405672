import { PLAYER_BUZZ_REQUEST } from "../websocketActionTypes";
import { send } from '@giantmachines/redux-websocket';

export default function playerBuzzAction(playerUuid, score, token) {
    return (dispatch) => {
        dispatch(send({ 
            action: PLAYER_BUZZ_REQUEST,
            playerUuid: playerUuid
        }));
    };
};