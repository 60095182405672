import React, { useState } from 'react';
import { Text, View, TextInput, Button, TouchableOpacity, ScrollView, Dimensions } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { styles, colors } from "../styles";
import { connect } from 'react-redux';
import requestGameAction from '../actions/requestGameAction';
import joinGameAction from '../actions/joinGameAction';
import { charactors } from "../src/charactors";
import { Avatar } from 'react-native-elements';
import LinearGradient from "react-native-linear-gradient";

class PlayerJoinScreen extends React.Component {

    componentDidMount = () => {
        this.props.requestGameAction(this.props.match.params.gameUuid);
    }

    render = () => {
        const { history } = this.props;

        return (
            <View style={styles.container}>
                {this.props.joinGameReducer.player !== null && this.props.gameEventReducer.game 
                    ? history.push("/lobby")
                    : null
                }
                <Text style={styles.label}>
                    {this.props.gameEventReducer.name}
                </Text>  
                <Text style={styles.label}>
                    PLAYER JOIN GAME: {this.props.gameEventReducer?.game?.name}
                </Text>

                {(this.props.gameEventReducer.game !== null) 
                    ? <JoinGameForm {...this.props} />
                    : null
                }
            </View>
        );
    }
}



const JoinGameForm = (props) => {
    const [ selectedChar, setSelectedChar ] = useState(null);
    const { control, handleSubmit, errors } = useForm();
    const onSubmit = data => {
        console.log("selectedChar", selectedChar);
        if (selectedChar !== null && data.playerName !== null) {
            props.joinGameAction(props.gameEventReducer.game.uuid, data.playerName, selectedChar.uuid);
        }
    };

    let playListHeight = Dimensions.get('window').height * 0.4;

    return (
        <View style={{
            flex: 1,
            justifyContent: "center",
            width: "100%",
            height: "100%",
            padding: 20,
        }}>
            <Text style={
                styles.label
            }>
                Select your player
            </Text>
            <View style={{
                height: playListHeight,
            }}>
                <LinearGradient
                    colors={[colors.darkBlue, "rgba(255,255,255,0)"]}
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 80,
                        width: "100%",
                        marginBottom: -78,
                        zIndex: 1
                    }}
                    pointerEvents="box-none"
                >
                </LinearGradient>             
                <ScrollView style={{
                        flexDirection: "row",
                        width: "100%",
                        height: "100%",      
                        paddingLeft: 15,


                    }}
                    showsVerticalScrollIndicator={false}
                >
                    <PlayerSelect selected={selectedChar} onChange={(char) => {console.log(char); setSelectedChar(char); }} 
                        style={{
                            paddingTop:40,
                            paddingBottom:40,
                        }}
                    />
                </ScrollView>
                <LinearGradient
                    colors={["rgba(255,255,255,0)",colors.darkBlue]}
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 80,
                        width: "100%",
                        marginTop: -78,
                        zIndex: 1
                    }}
                    pointerEvents="box-none"
                >
                </LinearGradient>                   
            </View>

            <View style={styles.inputContainer}>

                <Text style={styles.label}>
                    Enter your name
                </Text>
                <Controller
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                        <TextInput
                            style={styles.input}
                            onBlur={onBlur}
                            onChangeText={value => onChange(value)}
                            value={value}
                            name="playerName"
                        />
                    )}
                    name="playerName"
                    rules={{ 
                        required: true,
                        min: 3,
                        max: 255
                    }}
                    defaultValue=""
                />
                {errors.playerName?.type == "required" && <View style={styles.errorContainer}>
                    <Text style={styles.error}>
                        The name is required
                    </Text>
                </View>}
                {errors.playerName?.type == "min" && <View style={styles.errorContainer}>
                    <Text style={styles.error}>
                        The name must be at least 3 charactors long.
                    </Text>
                </View>}
                {errors.playerName?.type == "max" && <View style={styles.errorContainer}>
                    <Text style={styles.error}>
                        The name must be at most 255 charactors long.
                    </Text>
                </View>} 
            </View>

            <View style={styles.inputContainer}>
                <TouchableOpacity
                        style={styles.button}
                        onPress={handleSubmit(onSubmit)}
                    >
                        <Text style={styles.buttonText}>
                            Join game
                        </Text>
                </TouchableOpacity>
            </View>
        </View>    
    )
}

const PlayerSelect = (props) => {
    return (<View style={{
        paddingTop:20,
        paddingBottom:40,
    }}>
        {charactors.map(char => {
            return (<TouchableOpacity style={{
                flexDirection: "row"
            }} onPress={() => props.onChange(char)} key={char.uuid}>
                    <Avatar 
                        rounded
                        size="medium"
                        source={{"uri":require(`../src/images/${char.image}`)}} 
                        containerStyle={{
                            borderWidth: 3,
                            borderColor: (props.selected?.uuid == char.uuid) ? colors.pink : colors.darkBlue
                        }}
                    />                
                <Text style={[styles.label, {paddingLeft: 15}]}>
                    {char.name}
                </Text>
            </TouchableOpacity>)
        })}
    </View>)
}

const mapStateToProps=(state)=>{
    return {
        gameEventReducer: state.gameEventReducer,
        joinGameReducer: state.joinGameReducer,
    }
};

function mapDispatchToProps(dispatch) {
    return {
        requestGameAction: (gameUuid) => dispatch(requestGameAction(gameUuid)),
        joinGameAction: (gameUuid, playerName, charUuid) => dispatch(joinGameAction(gameUuid, playerName, charUuid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerJoinScreen);