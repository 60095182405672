import React, {useContext} from 'react';
import { Text, View, Linking, TextInput, Button, TouchableOpacity, Alert, StyleSheet } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { styles } from "../styles";
import { ErrorMessage } from '@hookform/error-message';
import { Provider, connect } from 'react-redux';
import createGameJoinQRAction from '../actions/createGameJoinQRAction';
import subscribeToUpdatesAction from '../actions/websocket/subscribeToUpdatesAction';
import GameShare from "./GameShare";
import { API_DOMAIN, APP_HOST_DOMAIN } from "../src/constants";
import PlayerList from "./PlayerList";
import AdminGameControls from "./AdminGameControls";

class ActiveGame extends React.Component {

    state = {
        hasMounted: false
    }

    componentDidMount = () => {
        this.props.createGameJoinQRAction(`${APP_HOST_DOMAIN}/join/${this.props.game.uuid}`);
        this.props.subscribeToUpdatesAction();
        this.setState({...this.state, hasMounted: true});
    }
    render = () => {
        return (
            <View style={styles.appContainerGutters}>
                <View style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'flex-start',                      
                }}>
                    <View style={{
                        width: "50%",
                        paddingRight: 10
                    }}>
                        {(false && this.props.gameEventReducer.game.gameStartedAt !== null )
                            ? null
                            : <GameShare game={this.props.gameEventReducer.game}/>
                        }
                        <AdminGameControls />
                    </View>
                    <View style={{
                        width: "50%",
                        paddingLeft: 10
                    }}>
                        <PlayerList />
                    </View>                    
                </View>
            </View>
        )        
    }
}

const mapStateToProps=(state)=>{
    return {
        gameEventReducer: state.gameEventReducer,
        qrReducer: state.qrReducer,
        playersReducer: state.playersReducer,
        playersReducer: state.playersReducer,
    }
};

function mapDispatchToProps(dispatch) {
    return {
        createGameJoinQRAction: (string) => dispatch(createGameJoinQRAction(string)),
        subscribeToUpdatesAction: (string) => dispatch(subscribeToUpdatesAction(string))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveGame);