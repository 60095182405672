import axios from "axios";
import { GAME_REQUEST_START, GAME_REQUEST_RESPONSE, GAME_REQUEST_ERROR } from "./actionTypes";
import { API_DOMAIN } from "../src/constants";

export default function requestGameAction(gameUuid) {
    return (dispatch, store) => {

        const apiUrl = `${API_DOMAIN}/game/${gameUuid}`;
        
        dispatch({
            type : GAME_REQUEST_START
        })

        return axios.get(`${apiUrl}`).then(response => {
            dispatch({
                    type : GAME_REQUEST_RESPONSE,
                    payload: response.data
                })
        }).catch(error => {
            dispatch({
                type : GAME_REQUEST_ERROR,
                payload: error
            })            
        });
    };
};

