import { StyleSheet } from "react-native";

export const colors = {
    "pink" : "#ec5998",
    "lightGrey" : "#eeeeee",
    "darkGrey" : "#848484",
    "darkBlue" : "#03001b",
}

export const styles = StyleSheet.create({
    screenWrapper: {
        width: "100%",
        height: "100%",
        backgroundColor: colors.darkBlue,
        position: 'relative',
        padding: 20
    },
    screenContainer: {
        width: "100%",
        height: "100%",
        position: 'relative',
        maxWidth: 2000,
    },
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    //   marginBottom: 15,
      position: 'relative',
      backgroundColor: "#03001b",
      width: "100%"
    },
    navigationMenuitem: {
        fontSize: 35,     
    },
    navigationMenuText: {
        fontSize: 20,
        paddingBottom: 20,
        paddingTop: 30
    },
    playerLobby: {
        playerContainer: {
            paddingBottom: "20%",
            marginBottom: "-20%",
            height: "100%",
        },
        containerBuzzer: {
            height: "20%",
        },
    },
    containerHalfV: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 15,
      position: 'relative',
      backgroundColor: "#03001b",
      width: "100%",
      height: "50%",
      maxHeight: "50%"
    },
    appContainerGutters: {
        width: "100%",
        padding: 50,
    },
    playerList: {
        width: "100%",
    },
    italic: {
        fontStyle: 'italic'
    },
    titleMain: {
        color:"#ffffff", 
        marginBottom: 20, 
        fontSize: 25,
        marginLeft:0
    },
    label: {
        color:"#ffffff", 
        margin: 20, 
        marginLeft:0,
        fontSize: 15
    },
    inputContainer: {
        marginTop: 20,
    },
    input: {
        backgroundColor: "#ffffff",
        height: 40,
        padding: 10,
        borderRadius: 4,
        marginTop: 0
    },
    button: {
        backgroundColor: "#ec5998",
        color: "#ec5998",
        height: 40,
        padding: 10,
        borderRadius: 4      ,
    },
    buttonTable: {
        backgroundColor: "#ec5998",
        color: "#ec5998",
        paddingTop: 10,
        paddingRight: 20,
        paddingBottom: 10,
        paddingLeft: 10,
        borderRadius: 4,
        textAlign: "center"
    },
    buttonText: {
        textAlign: "center",
        color: "#ffffff"
    },
    buttonTableText: {
        textAlign: "center",
        color: "#ffffff",
        padding: 0,
        margin: 0,
    },
    row: {
        flexDirection: "row"
    },
    col: {
        color:"#ffffff", 
        margin: 20, 
        marginLeft:0,
        flex: 1
    },
    tableRow: {
        flexDirection: "row"
    },
    tableCol: {
        color:"#ffffff", 
        margin: 20, 
        marginLeft:0,
        flex: 1
    },
    nav:{
        flexDirection: 'row',
        justifyContent: 'space-around',
    },    
    symbolBuzzer: {
        fontSize: 10
    },
    activityIndicatorContainer: {
      position: 'absolute',
      flex: 1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2,
    },
    error: {
      height: 17.5,
      color: "#ffffff",
    },
    errorContainer: {
        backgroundColor: "#ff3333",
        padding: 10,
        borderRadius: 4
    },

    tableColPlayerText: {
        paddingTop: 10,
    },
    playerRow: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start'
    },
    playerColAvatar :{
        width: '20%'
    },
    playerColOnline :{
        width: '10%'
    },
    playerColName :{
        width: '75%',
        marginBottom: 10
    },
    playerColScore :{
        width: '25%'
    },
    playerColActions :{
        width: '20%'
    },
    playerColBuzz :{
        width: '10%'
    },

    playerLabelName: {
        color: "#ffffff",
    },
    playerLabelScore: {
        color: "#d0d0d0"
    },
  });