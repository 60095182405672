import axios from "axios";
import { JOIN_GAME_REQUEST_START, JOIN_GAME_REQUEST_RESPONSE, JOIN_GAME_REQUEST_ERROR } from "./actionTypes";
import { API_DOMAIN } from "../src/constants";

export default function joinGameAction(gameUuid, playerName, charUuid) {
    return (dispatch, store) => {
        const apiUrl = `${API_DOMAIN}/player/create`;
        
        dispatch({
            type : JOIN_GAME_REQUEST_START
        })

        return axios.post(apiUrl, {
            "gameUuid": gameUuid,
            "name": playerName,
            "charUuid": charUuid
        }).then(response => {
            dispatch({
                type : JOIN_GAME_REQUEST_RESPONSE,
                payload: response.data
            })
        }).catch(error => {
            dispatch({
                type : JOIN_GAME_REQUEST_ERROR,
                payload: error
            })            
        });
    };
};

