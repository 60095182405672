import { PLAYER_SCORE_REQUEST } from "../websocketActionTypes";
import { send } from '@giantmachines/redux-websocket';

export default function playerScoreAction(playerUuid, score, token) {
    return (dispatch) => {
        dispatch(send({ 
            action: PLAYER_SCORE_REQUEST,
            playerUuid: playerUuid,
            score: score,
            token: token
        }));
    };
};