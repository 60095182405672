import axios from "axios";
import { RESTORE_ADMIN_START, RESTORE_ADMIN_RESPONSE, RESTORE_ADMIN_ERROR } from "./actionTypes";
import { API_DOMAIN } from "../src/constants";

export default function restoreAdminAction(gameUuid, adminUuid, token) {
    return (dispatch, store) => {
        const apiUrl = `${API_DOMAIN}/game/restore/admin`;
        
        dispatch({
            type : RESTORE_ADMIN_START
        })

        return axios.post(apiUrl, {
            "gameUuid": gameUuid,
            "adminUuid": adminUuid,
            "token": token
        }).then(response => {
            dispatch({
                type : RESTORE_ADMIN_RESPONSE,
                payload: response.data
            })
        }).catch(error => {
            dispatch({
                type : RESTORE_ADMIN_ERROR,
                payload: error
            })            
        });
    };
};