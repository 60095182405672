import {QR_GENERATING, QR_GENERATED, QR_ERROR, LEAVE_GAME} from "../actions/actionTypes";

const initialState = {
    hasError: false,
    errors: [],
    generating: false,
    generated: false,
    string: null,
    image: null
};

const qrReducer = function (state = initialState, action) {
    switch (action.type) {
      case QR_GENERATING:
        return {
            ...state, 
            hasError: false,
            errors: [],
            generating: true,
            generated: false,
            string: action.payload.string,
            image: null
        };
      case QR_GENERATED:
        return {
            ...state, 
            hasError: false,
            errors: [],
            generating: false,
            generated: true,
            image: action.payload.image
        };
      case QR_ERROR:
        return {
            ...state, 
            hasError: true,
            errors: action.payload.errors,
            generating: false,
            generated: false,
            image: null
        };
      case LEAVE_GAME:
        return {
          ...initialState
        }        
      default:
        return state;
    }
  };

export default qrReducer;