import { storageSet, storageGet } from "../src/storage";
import { MODE_HOST, MODE_CLIENT } from "../src/constants";
import { NEW_GAME_REQUEST_ERROR, NEW_GAME_REQUEST_RESPONSE, NEW_GAME_REQUEST_START, 
  GAME_REQUEST_START, GAME_REQUEST_RESPONSE, GAME_REQUEST_ERROR, RESTORE_ADMIN_RESPONSE, RESTORE_PLAYER_RESPONSE,
  JOIN_GAME_REQUEST_RESPONSE, LEAVE_GAME } from "../actions/actionTypes";
import { WS_GAME_REQUEST_RESPONSE, REDUX_WEBSOCKET_MESSAGE, GAME_BUZZER_STATUS_RESPONSE, GAME_START_RESPONSE,
  GAME_END_RESPONSE } from "../actions/websocketActionTypes";

const initialState = {
    loading: false,
    hasError: false,
    errors: [],
    game: null,
    admin: null,
    player: null,
    isAdmin: false,
    token: null,
    joinQR: null
};

const gameEventReducer = function (state = initialState, action) {
  if (action.type === REDUX_WEBSOCKET_MESSAGE) {
    switch (action.payload.message.event) {
      case WS_GAME_REQUEST_RESPONSE:
        return {
          ...state, 
          loading: false,
          hasError: action.payload.message.data.hasError,
          game: action.payload.message.data.game,
          admin: action.payload.message.data.admin,
          isAdmin: action.payload.message.data.isAdmin,
          token: null
        }
      case GAME_BUZZER_STATUS_RESPONSE:
      case GAME_START_RESPONSE:
      case GAME_END_RESPONSE:
        return {
          ...state,
          game: {
            ...action.payload.message.game
          }
        } 
    }
    return {
      ...state,
    };
  }

  switch (action.type) {
      case GAME_REQUEST_START:     
        return {
            ...state, 
            loading: true,
            hasError: false,
            game: null
      };
      case GAME_REQUEST_ERROR:
        return {
            ...state, 
            loading: false,
            hasError: action.payload.hasError,
            errors: action.payload.errors,
            game: action.payload.game,
            admin: action.payload.admin
        };
      case GAME_REQUEST_RESPONSE:
          return {
              ...state, 
              loading: false,
              hasError: action.payload.hasError,
              errors: action.payload.errors,
              game: action.payload.game,
              admin: action.payload.admin
          };        
      case NEW_GAME_REQUEST_START:     
        return {
            ...state, 
            loading: true,
            hasError: false,
            game: null,
            admin: null,
            isAdmin: false,
            token: null,
            joinQR: null
        };
      break;
      case RESTORE_PLAYER_RESPONSE:
       console.log("action.payload", action.payload) ;
        // storageSet('gameUuid', action.payload.game.uuid);
        // storageSet('adminUuid', action.payload.admin.uuid);
        // storageSet('playerUuid', action.payload.player.uuid);
        // storageSet('gameMode', MODE_CLIENT);        
        return {
          ...state, 
          loading: false,
          hasError: false,
          errors: [],
          game: action.payload.game,
          admin: action.payload.admin,
          player: action.payload.player,
          isAdmin: false,
          token: null,
          joinQR: null
      };
      break;
      case NEW_GAME_REQUEST_RESPONSE:
      case RESTORE_ADMIN_RESPONSE:
        storageSet('gameUuid', action.payload.game.uuid);
        storageSet('adminUuid', action.payload.admin.uuid);
        storageSet('adminToken', action.payload.token);
        storageSet('gameMode', MODE_HOST);
        return {
            ...state, 
            loading: false,
            hasError: false,
            errors: [],
            game: action.payload.game,
            admin: action.payload.admin,
            isAdmin: (action.payload.token !== null) ? true : false,
            token: action.payload.token,
            joinQR: null
        };
      break;
      case JOIN_GAME_REQUEST_RESPONSE:
        return {
          ...state, 
          player: action.payload.player
      };
      break;
      case NEW_GAME_REQUEST_ERROR:
            return {
                ...state, 
                loading: false,
                hasError: true,
                errors: action.payload.errors,
                game: null,
                admin: null,
                isAdmin: false,
                token: null,
                joinQR: null
            };        
        case GAME_REQUEST_RESPONSE:
            return {
                ...state, 
                loading: false,
                hasError: true,
                errors: action.payload.errors,
                game: null,
                admin: null,
                isAdmin: false,
                token: null,
                joinQR: null
            };
        case LEAVE_GAME:
          return {
            ...initialState
          }
      default:
        return state;
    }

    return state;
  };

export default gameEventReducer;