import { REDUX_WEBSOCKET_MESSAGE, NEW_PLAYER, PLAYER_UPDATE, GAME_REFRESH_PLAYERS, PLAYER_SCORE_RESPONSE, 
  PLAYER_BUZZ_RESPONSE, GAME_BUZZER_STATUS_RESPONSE, GAME_BUZZER_CLEAR_ALL_RESPONSE } from "../actions/websocketActionTypes";
import { LEAVE_GAME } from "../actions/actionTypes";

const initialState = {
  players: []
};

const initialNewPlayer = {
  uuid: null,
  gameUuid: null,
  charUuid: null,
  name: null,
  score: 0,
  isMe: false,
  online: false,
  buzzedAt: null,
  height: 150
}

const playersReducer = function (state = initialState, action) {
    if (action.type !== REDUX_WEBSOCKET_MESSAGE) {
      switch (action.type) {
        case LEAVE_GAME:
          return {
            ...initialState
          }
        default:
          return state;          
          break;
      }
    }

    switch (action.payload.message.event) {
      case NEW_PLAYER:
      case PLAYER_SCORE_RESPONSE:
        let playerIndex = state.players.findIndex(existingPlayer => {
          return existingPlayer.uuid === action.payload.message.player.uuid
        });
        if (playerIndex === -1) {
          // add player
          return {
            ...state,
            players: [
              ...state.players, 
              {
                ...initialNewPlayer,
                ...action.payload.message.player
              }
            ]
           }
        } else {
          return {
            ...state,
            players: state.players.map((player, i)=> {
              let updatedPlayer = {...player};
              if(player.uuid === action.payload.message.player.uuid) {
                updatedPlayer = {...updatedPlayer, ...action.payload.message.player}
              }
              return updatedPlayer;
            })
            // .sort((player1, player2) => (player1.score > player2.score) ? -1 : 1 )
           }
        }
      break;
      case PLAYER_BUZZ_RESPONSE:
        // update player
        let buzzIndex = state.players.findIndex(existingPlayer => {
          return existingPlayer.uuid === action.payload.message.player.uuid
        });        
        let newPlayers = state.players;
        newPlayers[buzzIndex] = {
          ...newPlayers[buzzIndex],
          ...{
            ...action.payload.message.player,
            buzzedAt: action.meta.timestamp.toString()
          }
        };
        return {
          ...state,
          players: newPlayers
          }
      break;      
      case PLAYER_UPDATE:
        let foundIndex;
        state.players.forEach((player, index) => {
          if (player.uuid == action.payload.message.player.uuid) {
            foundIndex = index;
          }
        });

        const newArray = [...state.players];

        if (foundIndex === -1) {
          newArray[foundIndex].online = action.payload.message.player.online
        }
        
        return { 
          ...state, //copying the orignal state
          players: newArray, //reassingning todos to new array
        }
      break;
      case GAME_REFRESH_PLAYERS:
        let existingPlayers = state.players;

        action.payload.message.players.forEach((updatedPlayer, updayedPlayerIndex) => {

          let foundIndex = null;
          if (existingPlayers.filter((existingPlayer, existingPlayerIndex) => {
              if (existingPlayer.uuid === updatedPlayer.uuid) {
                foundIndex = existingPlayerIndex;
              }
              return existingPlayer.uuid === updatedPlayer.uuid;
          }).length > 0) {
            existingPlayers[foundIndex] = {
              ...existingPlayers[foundIndex],
              ...updatedPlayer
            };
          } else {
            existingPlayers.push(
              {
                ...initialNewPlayer,
                ...updatedPlayer
              }
            );
          }
        })

        return {
          ...state,
          players: existingPlayers
        }
      break;
      case GAME_BUZZER_CLEAR_ALL_RESPONSE:
        return {
          ...state,
          players: state.players.map(player => {
            player.buzzedAt = null
            return player;
          })
        }
      break;      
      default:
        return state;
    }
  };

export default playersReducer;