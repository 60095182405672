import { LEAVE_GAME } from "./actionTypes";
import { disconnect } from '@giantmachines/redux-websocket';

export default function leaveGameAction() {
    return (dispatch, store) => {
        dispatch(disconnect());        
        dispatch({
            type : LEAVE_GAME
        })
    };
};

