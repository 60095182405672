import { REDUX_WEBSOCKET_MESSAGE, REDUX_WEBSOCKET_CLOSED, SUBSCRIBE_TO_UPDATES_ADMIN_STATUS, SUBSCRIBE_TO_UPDATES_PLAYER_STATUS } from "../actions/websocketActionTypes"
const initialState = {
  subscribedAdmin: false,
  subscribedPlayer: false
};

const websocketSubscriptionReducer = function (state = initialState, action) {
  if (action.type === REDUX_WEBSOCKET_CLOSED) {
    return {
      ...state, 
      subscribedAdmin: false,
      subscribedPlayer: false
    };
  }

  if (action.type === REDUX_WEBSOCKET_MESSAGE) {
    switch (action.payload.message.event) {
      case SUBSCRIBE_TO_UPDATES_ADMIN_STATUS:
        return {
            ...state, 
            subscribedAdmin: action.payload.message.subscribed
        };
      break;
      case SUBSCRIBE_TO_UPDATES_PLAYER_STATUS:
        return {
            ...state, 
            subscribedPlayer: action.payload.message.subscribed
        };
      break;
      default:
        return state;
    }
  }

  return state;
};

export default websocketSubscriptionReducer;