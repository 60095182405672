import axios from "axios";
import { RESTORE_ADMIN_START, RESTORE_ADMIN_RESPONSE, RESTORE_ADMIN_ERROR, RESTORE_PLAYER_START, RESTORE_PLAYER_RESPONSE, RESTORE_PLAYER_ERROR } from "./actionTypes";
import { API_DOMAIN } from "../src/constants";

export default function restorePlayerAction(gameUuid, adminUuid, playerUuid) {
    return (dispatch, store) => {
        const apiUrl = `${API_DOMAIN}/game/restore/player`;
        
        dispatch({
            type : RESTORE_PLAYER_START
        })

        return axios.post(apiUrl, {
            "gameUuid": gameUuid,
            "adminUuid": adminUuid,
            "playerUuid": playerUuid,
        }).then(response => {
            dispatch({
                type : RESTORE_PLAYER_RESPONSE,
                payload: response.data
            })
        }).catch(error => {
            dispatch({
                type : RESTORE_PLAYER_ERROR,
                payload: error
            })            
        });
    };
};