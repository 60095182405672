export const charactors = [{
    uuid: "creator",
    name: "Creator",
    image: "charactors/creator.png",
    buzzerSound: "creator.wav",
},{
    uuid: "doggy-mcdog-face",
    name: "Doggy McDogface",
    image: "charactors/doggy-mcdog-face.png",
    buzzerSound: "doggy-mcdogface.mov",
},{
    uuid: "brown-owl",
    name: "Brown Owl",
    image: "charactors/brown-owl.png",
    buzzerSound: "brownowl.mp3",
}, {
    uuid: "herbivore",
    name: "Herbivore",
    image: "charactors/herbivore.png",
    buzzerSound: "herbivore.mov",
},{
    uuid: "jam-tart",
    name: "Jam Tart",
    image: "charactors/jam-tart.png",
    buzzerSound: "jamtart.mov",
},{
    uuid: "little-wife",
    name: "Little Wife",
    image: "charactors/little-wife.png",
    buzzerSound: "littlewife.wav",
},{
    uuid: "mandeep",
    name: "Mandeep",
    image: "charactors/mandeep.png",
    buzzerSound: "mandeep.wav",
},{
    uuid: "semi-colon",
    name: "Semi Colon™️",
    image: "charactors/semi-colon.png",
    buzzerSound: "semi-colon.mov",
}];