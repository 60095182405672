import { GAME_REFRESH } from "../websocketActionTypes";
import { send } from '@giantmachines/redux-websocket';

export default function gameRefreshAction(gameUuid) {
    return (dispatch) => {
        console.log("DISPATCHED! GAME_REFRESH", gameUuid)

        dispatch(send({ 
            action: GAME_REFRESH,
            gameUuid: gameUuid
        }));
    };
};

