import { JOIN_GAME_REQUEST_START, JOIN_GAME_REQUEST_RESPONSE, JOIN_GAME_REQUEST_ERROR, RESTORE_PLAYER_RESPONSE,
   LEAVE_GAME } from "../actions/actionTypes";
import { storageSet } from "../src/storage";
import { MODE_CLIENT } from "../src/constants";

const initialState = {
    loading: false,
    hasError: false,
    errors: [],
    player: null
};

const joinGameReducer = function (state = initialState, action) {
  switch (action.type) {
    case RESTORE_PLAYER_RESPONSE:
        return {
          ...state, 
          ...state, 
          loading: true,
          hasError: false,
          errors: [],
          player: action.payload.player
      };    
      case JOIN_GAME_REQUEST_START:     
        return {
            ...state, 
            loading: true,
            hasError: false,
            errors: [],
            player: null
      };
      case JOIN_GAME_REQUEST_ERROR:
        return {
            ...state, 
            loading: false,
            hasError: action.payload.hasError,
            errors: action.payload.errors
        };
      case JOIN_GAME_REQUEST_RESPONSE:
          storageSet('gameUuid', action.payload.game.uuid);
          storageSet('token', null);
          storageSet('playerUuid', action.payload.player.uuid);
          storageSet('adminUuid', action.payload.admin.uuid);
          storageSet('gameMode', MODE_CLIENT);                
          return {
              ...state, 
              loading: false,
              hasError: false,
              errors: [],
              player: action.payload.player
          };
      case LEAVE_GAME:
        storageSet('gameUuid', null);
        storageSet('token', null);
        storageSet('playerUuid', null);
        storageSet('adminUuid', null);
        storageSet('gameMode', null);
        return {
          ...initialState
        }
      default:
        return state;
    }

    return state;
  };

export default joinGameReducer;