export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST"
export const UPDATE_CHAT_LOG = "UPDATE_CHAT_LOG"
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS";
export const JOIN_ROOM_SUCCESS = "JOIN_ROOM_SUCCESS";
export const SET_USERNAME = "SET_USERNAME";

export function updateChatLog(update){
    return {
        type: UPDATE_CHAT_LOG,
        update
    }
}