import React, { useRef, useEffect } from 'react';
import { Text, View, TextInput, TouchableOpacity, Dimensions, Animated } from "react-native";
import { Button } from 'react-native-elements'
import { useForm, Controller } from "react-hook-form";
import { colors, styles } from "../styles";
import { connect } from 'react-redux';
import createGameAction from '../actions/createGameAction';
import gameRefreshAction from '../actions/websocket/gameRefreshAction';
import playerBuzzAction from '../actions/websocket/playerBuzzAction';
import ActiveGame from "../components/ActiveGame";
import subscribeToUpdatesAction from "../actions/websocket/subscribeToUpdatesAction";
import PlayerList from "../components/PlayerList";
import { ScrollView } from 'react-native-gesture-handler';
import LinearGradient from "react-native-linear-gradient";
// import { usePrevious }  from "../src/helpers";
import Vibrate from "../src/vibrate/vibrate.js" ;

class PlayerLobbyScreen extends React.Component {

    state = {
        prevPlayers: []
    }

    componentDidMount = () => {
        this.props.subscribeToUpdatesAction();
    }

    componentDidUpdate(prevProps){
        (prevProps.websocketSubscriptionReducer.subscribedPlayer === false &&
            this.props.websocketSubscriptionReducer.subscribedPlayer === true) 
            ? this.props.gameRefreshAction(this.props.gameEventReducer.game.uuid)
            : null;
     }

    render = () => {
        const { history } = this.props;
        this.props.joinGameReducer.player === null || this.props.gameEventReducer.game === null
        ? history.push("/")
        : null

        const windowHeight = Dimensions.get('window').height - (20*2);
        const viewInfoHeight = windowHeight * 0.10;

        const playerScrollViewMaxHeight = (windowHeight *.75) - viewInfoHeight;
        return (           
            <View style={[styles.screenWrapper]}
            >
                <View style={[styles.screenContainer, {
                    flex: 1,
                    justifyContent: "space-between",
                    overflow: "hidden",                        
                }]}>
                    <View style={{
                        flex: 0.75
                    }}>
                        <View style={{
                            flex: 0.10,
                            overflow: "hidden",
                            // backgroundColor: "#ff0000",
                            marginRight: 50,
                            flexDirection: "row"
                        }}>
                            <View style={{width: "100%", height: 30, overflow: "hidden"}}>
                                <Text style={[styles.titleMain]}>
                                    {this.props.gameEventReducer.game?.name}
                                </Text>
                            </View>
                            <LinearGradient
                                colors={["rgba(0,0,0,0)", colors.darkBlue]}
                                style={{
                                    width: 50, height: "100%", 
                                    marginLeft: -50,                                        
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                useAngle={true}
                                angle={90}
                            ></LinearGradient>    
                        </View>
                        <LinearGradient
                                colors={[colors.darkBlue, "rgba(0,0,0,0)"]}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 80,
                                    width: "100%",
                                    marginBottom: -80,
                                    zIndex: 1
                                }}
                            >
                        </LinearGradient>                        
                        <View style={{
                            flex: 0.75,
                            width: "100%",
                            maxHeight: playerScrollViewMaxHeight,
                        }}>
                            <ScrollView
                                contentContainerStyle={{
                                    marginBottom: 40,
                                    marginTop: 40,
                                }}
                                showsVerticalScrollIndicator={false}
                            >
                                <PlayerList prevPlayers={this.state.prevPlayers} style={{
                                    width: "100%"
                                }}/>                        
                            </ScrollView>
                        </View>
                        <LinearGradient
                                colors={["rgba(0,0,0,0)", colors.darkBlue]}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 80,
                                    width: "100%",
                                    marginTop: -80,
                                }}
                            >
                        </LinearGradient>
                    </View>
                    <View style={{
                        flex: 0.25
                    }}>
                        <BuzzButton {...this.props} />
                    </View>
                </View>
            </View>
        );
    }
}

function BuzzButton(props) {
    let thisPlayer = props.playersReducer.players.find(player => {
        return player.uuid === props.gameEventReducer.player?.uuid
    });
    
    const fadeAnim = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0
    const animateColor = () => {
        Animated.timing(
            fadeAnim,
            {
                fromValue: 0,
                toValue: 1,
                duration: 3000,
            }
        ).start(() => {
            fadeAnim.setValue(0);
            animateColor();
        });
    }
    animateColor();
    
    const BuzzText = (<Text style={{
        fontSize: 60,
        textAlign: "center",
        color: "#ffffff"
    }}>
        BUZZ!
    </Text>);

    return (
        <TouchableOpacity
            style={{
                flex: 1,
                width: "100%",
                height: "100%",
            }}
            // onResponderStart={()=>console.log("mouse down!")}
            onPress={()=>{ if(props.gameEventReducer.game?.buzzersEnabled == true 
                && props.gameEventReducer?.player?.uuid && thisPlayer && thisPlayer.buzzedAt === null) {
                    Vibrate();
                    props.playerBuzzAction(props.gameEventReducer.player.uuid);
                }}
            }
        >
            { thisPlayer && thisPlayer.buzzedAt !== null && props.gameEventReducer.game.buzzersEnabled == true
            ? <Animated.View                 // Special animatable View
                    style={{
                        // ...props.style,
                        // opacity: fadeAnim,         // Bind opacity to animated value
                        padding: 20,
                        borderRadius: 10,
                        flex: 1,
                        width: "100%",
                        justifyContent: 'center',
                        backgroundColor: true ? fadeAnim.interpolate({
                                inputRange: [0, 1],
                                outputRange: ['rgba(255, 228, 95, 1)', 'rgba(210, 84, 10, 1)'],
                                extrapolateRight: 'clamp'
                            }) : "#3e424b",
                    }}
                >
                    {BuzzText}
                </Animated.View>
                : (props.gameEventReducer?.game?.buzzersEnabled !== false) 
                    ? <LinearGradient style={{
                        padding: 20,
                        borderRadius: 10,
                        flex: 1,
                        backgroundColor: colors.pink,
                        justifyContent: 'center',
                    }}
                    colors={[colors.pink, '#ec0067']}
                    >
                        {BuzzText}
                    </LinearGradient>
                    : <LinearGradient style={{
                        padding: 20,
                        borderRadius: 10,
                        flex: 1,
                        backgroundColor: colors.pink,
                        justifyContent: 'center',
                    }}
                    colors={["#b9b9b9", "#6d6b6b"]}
                    >
                        {BuzzText}
                    </LinearGradient>
            }
        </TouchableOpacity>
    );
}

const mapStateToProps=(state)=>{
    return {
        gameEventReducer: state.gameEventReducer,
        joinGameReducer: state.joinGameReducer,
        playersReducer: state.playersReducer,
        websocketSubscriptionReducer: state.websocketSubscriptionReducer,
    }
};

function mapDispatchToProps(dispatch) {
    return {
        createGameAction: (gameName, adminName) => dispatch(createGameAction(gameName, adminName)),
        subscribeToUpdatesAction: () => dispatch(subscribeToUpdatesAction()),
        gameRefreshAction: (gameUuid) => dispatch(gameRefreshAction(gameUuid)),
        playerBuzzAction: (playerUuid) => dispatch(playerBuzzAction(playerUuid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerLobbyScreen);