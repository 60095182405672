import { GAME_BUZZER_CLEAR_ALL_REQUEST } from "../websocketActionTypes";
import { send } from '@giantmachines/redux-websocket';

export default function buzzerClearAllAction(gameUuid, token) {
    return (dispatch) => {
        dispatch(send({ 
            action: GAME_BUZZER_CLEAR_ALL_REQUEST,
            gameUuid: gameUuid,
            token: token,
        }));
    };
};