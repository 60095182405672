import AsyncStorage from '@react-native-async-storage/async-storage';

const storePrefix = "BUZZ-APP";

export const storageSet = async (key, value) => {
    try {
console.log("setting", key, value)      

        await AsyncStorage.setItem(
            `${storePrefix}:${key}`,
            JSON.stringify(value)
        );
    } catch (error) {
        console.log(`error setting value in storage: ${key}`, error);
        throw error;
    }
};

export const storageGet = async (key) => {
    try {
      const value = await AsyncStorage.getItem(`${storePrefix}:${key}`);
console.log("FOUND", key, value)      
        if (value !== null) {
            return JSON.parse(value);
        }
    } catch (error) {
        console.log(`error retrieving value from storage: ${key}`, error);
        throw error;
    }
};