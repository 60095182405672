import React, { useState, useRef, useEffect } from 'react';
import { Text, View, TextInput, TouchableOpacity, TouchableHighlight, Alert } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { styles } from "../styles";
import { connect } from 'react-redux';
import createGameAction from '../actions/createGameAction';
import gameRefreshAction from '../actions/websocket/gameRefreshAction';
import restoreAdminAction from '../actions/restoreAdminAction';
import restorePlayerAction from '../actions/restorePlayerAction';
import subscribeToUpdatesAction from '../actions/websocket/subscribeToUpdatesAction';
import ActiveGame from "../components/ActiveGame";
import { storageGet } from "../src/storage";
import { MODE_CLIENT, MODE_HOST } from "../src/constants";
import Modal from "modal-react-native-web";
import { Audio } from 'expo-av';
import { charactors } from "../src/charactors";

const PlaySounds = (props) => {
    const [sound, setSound] = React.useState();
  
    let char = charactors.find(char => {
        return props.player.charUuid === char.uuid;
    })

    async function playSound() {
      console.log('Loading Sound');
      const { sound } = await Audio.Sound.createAsync(
         require(`../src/sounds/${char.buzzerSound}`)
      );
      setSound(sound);
  
      console.log('Playing Sound');
      await sound.playAsync(); }
  
    React.useEffect(() => {
      return sound
        ? () => {
            console.log('Unloading Sound');
            sound.unloadAsync(); }
        : undefined;
    }, [sound]);
    if (!sound) {
        playSound();
    }
    return (
      <View >
        {/* <Button title="Play Sound" onPress={playSound} /> */}
      </View>
    );
}

class GameCreateScreen extends React.Component {

    state = {
        sound: null
    }

    componentDidMount() {
        try {
            storageGet("gameMode").then(gameMode => {
                if (gameMode === MODE_HOST) {
                    storageGet("gameUuid").then(gameUuid => {
                        if (gameUuid !== null) {
                            storageGet("adminToken").then(adminToken => {
                                if (adminToken !== null) {
                                    storageGet("adminUuid").then(adminUuid => {
                                        if (adminUuid !== null) {
                                            // dispatch action
                                            this.props.restoreAdminAction(gameUuid, adminUuid, adminToken);
                                        }
                                    });
                                }
                            });
                            
                        }
                    });
                }
                if (gameMode === MODE_CLIENT) {
                    storageGet("gameUuid").then(gameUuid => {
                        if (gameUuid !== null) {
                            storageGet("playerUuid").then(playerUuid => {
                                if (playerUuid !== null) {
                                    storageGet("adminUuid").then(adminUuid => {
                                        if (adminUuid !== null) {
                                            // dispatch action
                                            this.props.restorePlayerAction(gameUuid, adminUuid, playerUuid);
                                        }
                                    });
                                }
                            });
                            
                        }
                    });
                }                
            });

        } catch (e) {
            console.log("error", e)
        }
    }

    componentDidUpdate(prevProps) {
     
        const { history } = this.props;

        // admin now subscribed
        (prevProps.websocketSubscriptionReducer.subscribedAdmin === false &&
            this.props.websocketSubscriptionReducer.subscribedAdmin === true) 
            ? this.props.gameRefreshAction(this.props.gameEventReducer.game.uuid)
            : null;

        // player now subscribed
        (prevProps.websocketSubscriptionReducer.subscribedPlayer === false &&
            this.props.websocketSubscriptionReducer.subscribedPlayer === true) 
            ? this.props.gameRefreshAction(this.props.gameEventReducer.game.uuid)
            : null;

        // game has been restored
        (prevProps.gameEventReducer.admin?.uuid == null &&
            this.props.gameEventReducer.admin?.uuid != true &&
            prevProps.gameEventReducer.game?.uuid == null &&
            this.props.gameEventReducer.game?.uuid != true &&
            prevProps.gameEventReducer.token == null &&
            this.props.gameEventReducer.token != true            
        ) 
            ? this.props.subscribeToUpdatesAction()
            : null;

        // player is in create screen
        {this.props.joinGameReducer.player !== null && this.props.gameEventReducer.game 
            ? history.push("/lobby")
            : null
        }            

        // game has ended
        this.props.gameEventReducer.game?.gameEndedAt !== null && this.props.gameEventReducer.game?.gameEndedAt !== undefined
            ? history.push("/results") 
            : null;

        
     }

    render = () => {

        return (
            <View style={styles.screenWrapper}>
                {this.props.playersReducer.players.map(player => {
                    if (player.buzzedAt !== null && player.buzzedAt !== undefined  ) {
                        return <PlaySounds key={player.uuid} player={player}/>
                    }
                })}
                {/* <PlaySounds {...this.props } /> */}
                <View style={styles.screenContainer}>
                    <View style={styles.container}>
                        <Text style={styles.titleMain}>
                            {(this.props.gameEventReducer.game?.uuid) 
                                ? `Welcome to ${this.props.gameEventReducer.game.name}!`
                                : "Create a game and invite friends to play"
                            }
                        </Text>
                        {/* <Button
                            onPress={this.props.navigation.openDrawer}
                            title="Open navigation drawer"
                        />         */}
                        {this.props.gameEventReducer.game == null 
                            ? <CreateForm 
                                gameEventReducer={this.props.gameEventReducer} 
                                createGameAction={this.props.createGameAction} 
                            />
                            : <ActiveGame game={this.props.gameEventReducer.game}/>
                        }    
                    </View>
                </View>
            </View>
        );
    }
}

function CreateForm(props){
    const { control, handleSubmit, errors } = useForm();
    const onSubmit = data => {
        props.createGameAction(data.gameName, data.adminName);
    };

    return (
        <View>
            <View style={styles.inputContainer}>
                <Text style={styles.label}>
                    Game Name
                </Text>
                <Controller
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                        <TextInput
                            style={styles.input}
                            onBlur={onBlur}
                            onChangeText={value => onChange(value)}
                            value={value}
                            name="gameName"
                        />
                    )}
                    name="gameName"
                    rules={{ 
                        required: true,
                        min: 3,
                        max: 255
                    }}
                    defaultValue=""
                />
                {errors.gameName?.type == "required" && <View style={styles.errorContainer}>
                    <Text style={styles.error}>
                        The game name is required
                    </Text>
                </View>}
                {errors.gameName?.type == "min" && <View style={styles.errorContainer}>
                    <Text style={styles.error}>
                        The game name must be at least 3 charactors long.
                    </Text>
                </View>}
                {errors.gameName?.type == "max" && <View style={styles.errorContainer}>
                    <Text style={styles.error}>
                        The game name must be at most 255 charactors long.
                    </Text>
                </View>}

                <Text style={styles.label}>
                    Your Name
                </Text>
                <Controller
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                        <TextInput
                            style={styles.input}
                            onBlur={onBlur}
                            onChangeText={value => onChange(value)}
                            value={value}
                            name="adminName"
                        />
                    )}
                    name="adminName"
                    rules={{ 
                        required: true,
                        min: 3,
                        max: 255
                    }}
                    defaultValue=""
                />
                {errors.adminName?.type == "required" && <View style={styles.errorContainer}>
                    <Text style={styles.error}>
                        The game name is required
                    </Text>
                </View>}
                {errors.adminName?.type == "min" && <View style={styles.errorContainer}>
                    <Text style={styles.error}>
                        The game name must be at least 3 charactors long.
                    </Text>
                </View>}
                {errors.adminName?.type == "max" && <View style={styles.errorContainer}>
                    <Text style={styles.error}>
                        The game name must be at most 255 charactors long.
                    </Text>
                </View>}                
            </View>

            <View style={styles.inputContainer}>
                <TouchableOpacity
                        style={styles.button}
                        onPress={handleSubmit(onSubmit)}
                    >
                        <Text style={styles.buttonText}>
                            Create Game
                        </Text>
                </TouchableOpacity>
            </View>
        </View>    
    )
}

const mapStateToProps=(state)=>{
    return {
        joinGameReducer: state.joinGameReducer,
        gameEventReducer: state.gameEventReducer,
        playersReducer: state.playersReducer,
        qrReducer: state.qrReducer,
        websocketSubscriptionReducer: state.websocketSubscriptionReducer,
    }
};

function mapDispatchToProps(dispatch) {
    return {
        createGameAction: (gameName, adminName) => dispatch(createGameAction(gameName, adminName)),
        gameRefreshAction: (gameUuid) => dispatch(gameRefreshAction(gameUuid)),
        restoreAdminAction: (admin, adminUuid, adminToken) => dispatch(restoreAdminAction(admin, adminUuid, adminToken)),
        restorePlayerAction: (gameUuid, adminUuid, playerUuid) => dispatch(restorePlayerAction(gameUuid, adminUuid, playerUuid)),
        subscribeToUpdatesAction: () => dispatch(subscribeToUpdatesAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameCreateScreen);