import React, { useState, useRef, useEffect } from 'react';
import { View, ScrollView, Text, Image, LayoutAnimation, Animated} from 'react-native';
import { styles } from "../styles";
import playerScoreAction from "../actions/websocket/playerScoreAction";
import playerBuzzAction from "../actions/websocket/playerBuzzAction";
import { connect } from 'react-redux';
import { ListItem, Avatar, Badge, Button } from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome';
import { charactors } from "../src/charactors";

export const sortPlayers = (players, ignoreBuzzes) => {
    players.sort((player1, player2) => {
        let p2Date = new Date(player2.buzzedAt);
        p2Date = p2Date == null 
            ? nowTime.getTime() 
            : p2Date.getTime();
        let p1Date = new Date(player1.buzzedAt);
        p1Date = p1Date == null 
            ? nowTime.getTime() 
            : p1Date.getTime();
        if(p1Date < p2Date) {
            return 1;
        } else if (p1Date > p2Date) {
            return -1;
        } else {
            if(player1.score > player2.score) {
                return -1;
            } else if (player1.score < player2.score) {
                return 1;
            } else {
                if(player1.name > player2.name) {
                    return 1;
                } else if (player1.name < player2.name) {
                    return -1;
                } else {
                    return 0;
                }
            }
        }
    });

    if (ignoreBuzzes !== true) {
        let playersBuzzed = players.filter(player => player.buzzedAt !== null)
        .sort((player1, player2) => {
            let p2Date = new Date(player2.buzzedAt);
            p2Date = p2Date == null 
                ? nowTime.getTime() 
                : p2Date.getTime();
            let p1Date = new Date(player1.buzzedAt);
            p1Date = p1Date == null 
                ? nowTime.getTime() 
                : p1Date.getTime();
            if(p1Date < p2Date) {
                return -1;
            } else if (p1Date > p2Date) {
                return 1;
            } else {
                return 0;
            }              
        });        
        let playersNotBuzzed = players.filter(player => player.buzzedAt === null);
        players = playersBuzzed.concat(playersNotBuzzed);
    }

    return players;
}

const PlayerList = (props) => {
    let nowTime = new Date();
    let players;

    if (!props.players) {
        players = [...props.playersReducer.players]
    } else {
        players = props.players;
    }
    players = sortPlayers(players);

    let compProps = props;
    return (
        <View style={styles.playerList}>
            <ScrollView style={{
            }}>
                { players.map((player, index) => {
                        return  (
                            <View 
                                key={player.uuid} 
                                style={{ 
                                    opacity: props.opacity,
                                    // paddingBottom: (players.length === (index+1)) ? 50 : 0
                                }}
                            >
                                <Player {...compProps} player={player} playerIndex={index}/>
                            </View>
                        )
                })}
                {props.playersReducer.players.length !== 0 
                    ? null
                    : <Text style={styles.label}>
                        Waiting for players to join...
                    </Text>
                    //     <Image 
                    //     source={require("../src/images/loading.gif")} 
                    //     style={{
                    //         width: "100%",
                    //         height: undefined,
                    //         spectRatio: 16/11
                    //     }}
                    // />
                }
            </ScrollView>
            {/* </Transitioning.View> */}
        </View>
    )
}

const ScoreButtons = (props) => {
    return (
        <View>
            <Button onPress={()=>{ props.playerScoreAction(props.player.uuid, 10, props.token) & console.log("SCORE +10")}} title="+10"/>
            <Button onPress={()=>{ props.playerScoreAction(props.player.uuid, -10, props.token) & console.log("SCORE -10")}} title="-10"/>
        </View>
    )    
}

const getOrdinal = (n) => {
    return["st","nd","rd"][((n+90)%100-10)%10-1]||"th";
}

const Player = (props) => {

    const fadeAnim = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0
    const animateColor = () => {
        Animated.timing(
            fadeAnim,
            {
                fromValue: 0,
                toValue: 1,
                duration: 3000,
            }
        ).start(() => {
            fadeAnim.setValue(0);
            animateColor();
        });
    }
    animateColor();
    
    let incOrdinal = (props.incOrdinal != undefined ? props.incOrdinal : 0)
    let getOrdinalColor = (index) => {
        let mappedIndex = incOrdinal + index;
        switch (mappedIndex) {
            case 0:
                return '#ffa600';
                break;
            case 1:
                return '#d4d4d4';  
                break;
            case 2:
                return '#a77400';  
                break;
            default:
                break;
        }
    }
    
    return (
        <Animated.View                 // Special animatable View
            style={{
                // ...props.style,
                // opacity: fadeAnim,         // Bind opacity to animated value
                marginBottom: 20,
                borderRadius: 10,
                backgroundColor: props.player.buzzedAt ? fadeAnim.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['rgba(255, 228, 95, 1)', 'rgba(210, 84, 10, 1)'],
                        extrapolateRight: 'clamp'
                    }) : "#3e424b",
            }}
        >        
            <ListItem  containerStyle={{
                backgroundColor: 'rgba(1,1,1,0)',
                // backgroundColor: props.buzzedAt ? "#ff00ff" : "#3e424b",
                // marginBottom: 20,
                borderRadius: 10,
                // backgroundColor: fadeAnim.interpolate({
                //     inputRange: [0, 1],
                //     outputRange: ['rgba(255, 228, 95, 1)', 'rgba(210, 84, 10, 1)'],
                //     extrapolateRight: 'clamp'
                // })                
            }}
                key={props.player.uuid}
            >
                <View>
                    <Avatar 
                        rounded
                        size="medium"
                        source={{uri: require(`../src/images/${charactors.find(char => char.uuid == props.player.charUuid).image}`) }} 
                    />
                    <Badge
                        status={props.player.online ? 'success' : 'warning'}
                        containerStyle={{ position: 'absolute', bottom: -5, right: -5 }}
                        badgeStyle={{width: 20, height: 20, borderRadius: 45}}
                    />
                </View>
                <View>
                    {(props.gameEventReducer.game.gameStartedAt === null) 
                        ? null
                        : (props.playerIndex <= (2-incOrdinal)) 
                            ? <Icon
                                name="trophy"
                                size={40}
                                color={getOrdinalColor(props.playerIndex)}
                                style={{
                                    
                                    // color: (props.playerIndex == (0+incOrdinal))
                                    //     ? '#ffa600'
                                    //     : (props.playerIndex == (1+incOrdinal)) 
                                    //         ? '#d4d4d4' 
                                    //         : '#a77400',
                                }}
                            />
                            : <View style={{
                                backgroundColor: "#eeeeee",
                                padding: 0,
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 40,
                                width: 40,
                                height: 40,
                            }}>
                                <Text style={{color: "#333333"}}>{props.playerIndex + 1 + incOrdinal}
                                {getOrdinal(props.playerIndex + 1 + incOrdinal)}</Text>
                            </View>                          
                    }
                </View>                        
                <ListItem.Content>
                    <View style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start',
                        paddingTop: 5,
                        width: "100%"
                    }}>
                        <View style={{width: props.gameEventReducer.isAdmin == false ? "80%" : "30%"}}>
                            <ListItem.Title style={styles.playerLabelName}>
                                {props.player.name}
                            </ListItem.Title>

                            <Text style={styles.playerLabelScore}>
                                {props.player.score} points
                            </Text>
                        </View>
                        <View style={{width:"20%"}} />
                        {props.gameEventReducer.isAdmin == false 
                            ? null 
                            : <View style={{
                                    width:"50%",
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}>
                                    {(props.gameEventReducer.game.gameStartedAt === null || 
                                        props.gameEventReducer.game.gameEndedAt !== null || 
                                        !props.gameEventReducer.isAdmin
                                    ) ? null
                                        :
                                            <View style={{
                                                flexDirection: 'row',
                                                position: 'absolute', 
                                                right: 0,
                                            }}>
                                                <Icon
                                                    name="minus-circle"
                                                    size={40}
                                                    color="#ec5998"
                                                    onPress={()=>{ props.playerScoreAction(props.player.uuid, -10, props.gameEventReducer.token)}}
                                                />                                    
                                                <Icon
                                                    name="plus-circle"
                                                    size={40}
                                                    color="#ec5998"
                                                    style={{marginLeft: 10}}
                                                    onPress={()=>{ props.playerScoreAction(props.player.uuid, 10, props.gameEventReducer.token)}}
                                                />
                                            </View>
                                    }
                                </View>
                        }
                    </View>
                </ListItem.Content>
            </ListItem>
        </Animated.View>
    )
}

const mapStateToProps=(state)=>{
    return {
        playersReducer: state.playersReducer,
        gameEventReducer: state.gameEventReducer,
        joinGameReducer: state.joinGameReducer,
    }
};


function mapDispatchToProps(dispatch) {
    return {
        playerScoreAction: (playerUuid, score, token) => dispatch(playerScoreAction(playerUuid, score, token)),
        playerBuzzAction: (playerUuid) => dispatch(playerBuzzAction(playerUuid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerList);
