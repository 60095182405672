import { useRef, useEffect } from "react";

function usePrevious(value, initial) {
  const targetRef = useRef(value);
  const previousRef = useRef(initial);

  if (targetRef.current !== value) {
    // The value changed.
    previousRef.current = targetRef.current;
    targetRef.current = value;
  }

  return previousRef.current;
}

export const isSetsEqual = (a, b) => {
    return a && b && a.size === b.size && [...a].every(value => b.has(value));
};  

export const hexToRGB = (hex) => {
  hex = (hex.charAt(0) === '#') ? hex.substring(1) : hex;
  hex = '0x' + hex
  let r = hex >> 16 & 0xFF
  let g = hex >> 8 & 0xFF
  let b = hex & 0xFF
  return `${r}, ${g}, ${b}`
}
