import React, { useRef, useEffect, useState } from 'react';
import { Animated, Text, View } from 'react-native';

const FadeInView = (props) => {
  const fadeAnim = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0

    const animateColor = () => {
        Animated.timing(
            fadeAnim,
            {
              fromValue: 0,
              toValue: 1,
              duration: 3000,
            }
        ).start(() => {
            fadeAnim.setValue(0);
            animateColor();
        });           
    }

  return (
    <Animated.View                 // Special animatable View
      style={{
        ...props.style,
        // opacity: fadeAnim,         // Bind opacity to animated value
        backgroundColor: fadeAnim.interpolate({
            inputRange: [0, 1],
            outputRange: ['rgba(255, 228, 95, 1)', 'rgba(210, 84, 10, 1)'],
            extrapolateRight: 'clamp'
        })
      }}
    >
        <Text style={{fontSize: 28, textAlign: 'center', margin: 10}}
            onPress={() => animateColor()}
        
        >Fading in</Text>
    </Animated.View>
  );
}

// You can then use your `FadeInView` in place of a `View` in your components:
export default () => {
  return (
    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <FadeInView style={{width: 250, height: 50, backgroundColor: 'powderblue'}}>
        <Text style={{fontSize: 28, textAlign: 'center', margin: 10}}>Fading in</Text>
      </FadeInView>
    </View>
  )
}