import {NEW_GAME_REQUEST_START, NEW_GAME_REQUEST_RESPONSE, NEW_GAME_REQUEST_ERROR } from './actionTypes';
import {API_DOMAIN} from '../src/constants';
import axios from 'axios';

const apiUrl = `${API_DOMAIN}/game`;

export default function createGameAction(gameName, adminName) {
    return (dispatch) => {
        dispatch({
            type : NEW_GAME_REQUEST_START
        })
        return axios.post(`${apiUrl}`,{
            "name": gameName,
            "admin": {
                "name": adminName
            }                
        }).then(response => {
            dispatch({
                    type : NEW_GAME_REQUEST_RESPONSE,
                    payload: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type : NEW_GAME_REQUEST_ERROR,
                    payload: error
                })            
            });        
    };
};

