import React, { useState } from 'react';
import { View, Text, Switch } from 'react-native';
import { styles, colors } from "../styles";
import buzzerSetStatusAction from "../actions/websocket/buzzerSetStatusAction";
import buzzerClearAllAction from "../actions/websocket/buzzerClearAllAction";
import gameStartAction from "../actions/websocket/gameStartAction.js";
import gameEndAction from "../actions/websocket/gameEndAction";
import { connect } from 'react-redux';
import { ListItem, Avatar, Badge, Button } from 'react-native-elements'

class AdminGameControls extends React.Component {

    state = {
        confirmEndGameModalOpen: false    
    }

    render() {
        return (
            <View>
                {/* <ModalConfirm {...this.props} visible={this.state.confirmEndGameModalOpen} 
                    confirm={()=>{
                        console.log("end game confirmed");
                        this.props.gameEndAction(
                            this.props.gameEventReducer.game?.uuid,
                            this.props.gameEventReducer.token
                        )                        
                    }}
                    cancel={()=>this.setState(false)}
                >
                    <Text>
                        MODAL!
                    </Text>
                </ModalConfirm>                 */}
                <Text style={styles.label}>
                    Game Actions:
                </Text>
                <View style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'flex-start',                    
                }}>
                    <View style={{
                        alignSelf: 'flex-start'
                    }}>

                        <Text style={{
                            fontSize: 20,
                            margin: 20,
                            color: "#ffffff",
                        }}>
                            Buzzers
                        </Text>
                    </View>
                    <View>
                        <Switch
                            // trackColor={{ false: colors.darkGrey, true: colors.lightGrey }}
                            // thumbColor={this.props.gameEventReducer.game?.buzzersEnabled ? '#000000' : "#ff00ff"}
                            // ios_backgroundColor="#3e3e3e"
                            onValueChange={()=>this.props.buzzerSetStatusAction(
                                !this.props.gameEventReducer.game?.buzzersEnabled,
                                this.props.gameEventReducer.game?.uuid,
                                this.props.gameEventReducer.token
                            )}
                            value={this.props.gameEventReducer.game?.buzzersEnabled}
                            style={{
                                margin: 20
                            }}
                        />
                    </View>
                </View>
                <View style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'flex-start',                    
                }}>
                    <Button onPress={()=>this.props.buzzerClearAllAction(
                            this.props.gameEventReducer.game?.uuid,
                            this.props.gameEventReducer.token,
                        )} 
                        title={"Clear buzzers"}
                        buttonStyle={{
                            backgroundColor: colors.pink,
                        }}
                        containerStyle={{
                            width: "50%",
                            paddingLeft: 10
                        }}                            
                    />                                       
                    {(this.props.gameEventReducer.game.gameStartedAt === null )
                        ? <Button 
                            title="Start Game"
                            onPress={()=>this.props.gameStartAction(
                                this.props.gameEventReducer.game?.uuid,
                                this.props.gameEventReducer.token
                            )}
                            buttonStyle={{
                                backgroundColor: colors.pink
                            }}
                            containerStyle={{
                                width: "50%",
                                paddingLeft: 10
                            }}                        
                        />
                        : null
                    } 
                    {/* {(this.props.gameEventReducer.game.gameStartedAt === null )
                        ? <Button 
                            title="Start Game"
                            onPress={()=>this.props.gameStartAction(
                                this.props.gameEventReducer.game?.uuid,
                                this.props.gameEventReducer.token
                            )}
                            buttonStyle={{
                                backgroundColor: colors.pink
                            }}
                            containerStyle={{
                                width: "50%",
                                paddingLeft: 10
                            }}
                            />
                        : null } */}

                        {(this.props.gameEventReducer.game.gameEndedAt === null) 
                            ? <Button 
                                title="End Game"
                                onPress={()=>{
                                    // this.setState({
                                    //     ...this.state,
                                    //     confirmEndGameModalOpen: true
                                    // })
                                    this.props.gameEndAction(
                                        this.props.gameEventReducer.game?.uuid,
                                        this.props.gameEventReducer.token
                                    )                                    
                                }}
                                buttonStyle={{
                                    backgroundColor: colors.pink
                                }}                        
                                containerStyle={{
                                    width: "50%",
                                    paddingLeft: 10,
                                    marginTop: 50
                                }}                            
                            /> : null
                        }
                </View>
            </View>
        )
    }
}


const ModalConfirm = (props) => {
    const [modalVisible, setModalVisible] = useState(true);

    if (props.visible != modalVisible) {
        setModalVisible(!modalVisible);
    }

    let containerStyles = props.containerStyles ? props.containerStyles : {}

    return (
         modalVisible 
            ? 
                <View style={{
                    width: "100%",
                    height: "100%",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    backgroundColor: "#ffffff",
                    ...containerStyles
                }}> 
                    { props.children }
                </View>
            : null
      )    
};

const mapStateToProps=(state)=>{
    return {
        gameEventReducer: state.gameEventReducer
    }
};

function mapDispatchToProps(dispatch) {
    return {
        buzzerClearAllAction: (gameUuid, token) => dispatch(buzzerClearAllAction(gameUuid, token)),
        buzzerSetStatusAction: (enabled, gameUuid, token) => dispatch(buzzerSetStatusAction(enabled, gameUuid, token)),
        gameStartAction: (gameUuid, token) => dispatch(gameStartAction(gameUuid, token)),
        gameEndAction: (gameUuid, token) => dispatch(gameEndAction(gameUuid, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminGameControls);