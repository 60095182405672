import React from 'react';
import { Text, View, Button } from "react-native";
import { styles } from "../styles";

export default class PlayerJoinScreen extends React.Component {
    render = () => {
        const { history } = this.props;

        return (
            <View style={styles.container}>  
                <Text style={styles.label}>
                    Thanks for playing! Why not create a new game of your own?
                </Text>
                <Button title="Create Game" onPress={() => history.push("/")} />
            </View>
        );
    }
}