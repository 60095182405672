import { GAME_BUZZER_STATUS_REQUEST } from "../websocketActionTypes";
import { send } from '@giantmachines/redux-websocket';

export default function buzzerSetStatusAction(status, gameUuid, token) {
    return (dispatch) => {
        dispatch(send({ 
            action: GAME_BUZZER_STATUS_REQUEST,
            status: status,
            gameUuid: gameUuid,
            token: token,
        }));
    };
};