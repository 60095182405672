import { GAME_END_REQUEST } from "../websocketActionTypes";
import { send } from '@giantmachines/redux-websocket';

export default function gameEndAction(gameUuid, token) {
    return (dispatch) => {
        dispatch(send({ 
            action: GAME_END_REQUEST,
            gameUuid: gameUuid,
            token: token,
        }));
    };
};