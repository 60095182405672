import { GAME_START_REQUEST } from "../websocketActionTypes";
import { send } from '@giantmachines/redux-websocket';

export default function gameStartAction(gameUuid, token) {
    return (dispatch) => {
        dispatch(send({ 
            action: GAME_START_REQUEST,
            gameUuid: gameUuid,
            token: token,
        }));
    };
};